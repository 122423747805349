import { App } from 'vue'

import Components from './components'

const register = (app: App<Element>): void => {
  Components.register(app)
}

export default {
  register,
}
