import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_select = _resolveComponent("control-select")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createBlock(_component_control_select, {
    modelValue: $setup.model,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      $setup.debounceUpdate
    ],
    name: $setup.props.filter.name,
    "track-by": $setup.props.filter.meta.value,
    multiple: $setup.props.filter.meta.multiple || false,
    searchable: true,
    "internal-search": false,
    "allow-empty": true,
    options: $setup.result.items,
    "input-label": $setup.props.filter.title,
    "option-label": $setup.filterLabel,
    class: _normalizeClass($props.inputClass),
    placeholder: $setup.props.placeholder || '',
    onSearchChange: $setup.debounceSearch
  }, {
    afterList: _withCtx(() => [
      ($setup.hasNextPage)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
            [_directive_observe_visibility, $setup.setVisibility]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "track-by", "multiple", "options", "input-label", "option-label", "class", "placeholder", "onUpdate:modelValue", "onSearchChange"]))
}