import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "control-button__body" }
const _hoisted_2 = {
  key: 0,
  class: "control-button__attachment control-button__attachment--variant_prepend"
}
const _hoisted_3 = {
  key: 1,
  class: "control-button__attachment control-button__attachment--variant_append"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.disabled && 'a' == $props.tag ? 'div' : $props.tag), _mergeProps({
    class: ["control-button", $setup.blockClasses]
  }, { 'aria-disabled': $props.disabled, 'readonly': $props.readonly, 'disabled': $props.disabled }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$slots.prepend)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "prepend")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["control-button__element", { 'control-button__element--icon_prepend': _ctx.$slots.prepend, 'control-button__element--icon_append': _ctx.$slots.append }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        (_ctx.$slots.append)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "append")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["class"]))
}