import { App } from 'vue'

import Base from './Base'

const register = (app: App<Element>): void => {
  Base.register(app)
}

export default {
  register,
}
