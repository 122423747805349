export const accordion = {
  mounted: (node: HTMLElement): any => {
    const triggers = node.querySelectorAll<HTMLElement>('.js-accordion-trigger')

    triggers.forEach((el: HTMLElement, index) => {
      el.addEventListener('click', () => {
        triggers.forEach((elem: HTMLElement, ind) => {
          if (index !== ind) {
            elem.closest('.js-accordion-item')?.classList.remove('is-active')
          }
        })

        el.closest('.js-accordion-item')?.classList.toggle('is-active')
      })
    })
  }
}
