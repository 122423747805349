import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("input", {
      class: "is-hidden",
      type: "file",
      accept: ".jpg, .jpeg, .png",
      onChange: $setup.loadImage
    }, null, 32)
  ]))
}